import { useState, useEffect } from 'react';
import './App.css';
import {
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import liff from '@line/liff';
import Spinner from 'react-bootstrap/Spinner';
import Header from './Components/Header';
import Footer from './Components/Footer/Footer';

import MemberMain from './Pages/MemberMain';
import EditProfilePage from './Pages/EditProfilePaje';
import Appointment from './Pages/Appointment';
import AppointmentList from './Components/SubAppointment/AppointmentList';
import Coupon from './Pages/Coupon';
import CouponDetail from './Components/SubCoupon/CouponDetail';
import DateTimeModel from './Components/SubAppointment/DateTimeModel';
import AppointmentConfirm from './Components/SubAppointment/AppointmentConfirm';
import AppointmentDetail from './Components/SubAppointment/AppointmentDetail';
import OTPAuth from './Pages/OTPAuth';
import AddInviteCode from './Pages/AddInviteCode';
import SelectPartner from './Pages/SelectPartner';

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;
// const SERVER_BASE_URL = "http://localhost";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const userId = useSelector(state => state.userId);
  const pictureUrl = useSelector(state => state.pictureUrl);

  const [liffStatus, setLiffStatus] = useState(false);
  const [fetchFromDB, setFetchFromDB] = useState(false);
  const [setupStoreDBName, setSetupStoreDBName] = useState(false);

  const [tmpDisplayName, setTmpDisplayName] = useState('');

  const [userExisted, setUserExisted] = useState(false);

  const [firstTimeVisit, setFirstTimeVisit] = useState(false);

  const [liffIdisSeted, setLiffIdisSeted] = useState(false);
  const [liffId, setLiffId] = useState('');

  const [storeDB, setStoreDB] = useState('');
  const [storeName, setStoreName] = useState("POWERED BY WEIZ STUDIO");

  const [mainColorStyle, setMainColorStyle] = useState('');
  const [subColorStyle, setSubColorStyle] = useState('');

  // 1. 先透過 url 的參數(storeDB) 來識別要抓哪一個商家的 DB 資料
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const storeDBParam = params.get('storeDB');
    if (storeDBParam) {
      setStoreDB(storeDBParam);
      dispatch({ type: 'SET_STORE_DB', payload: storeDBParam });
      // 在這裡可以進行相應的初始化操作，例如根據 storeDB 加載相應的資料
      setSetupStoreDBName(true);
    }
  }, []);

  // 2. 若從網址抓到 storeDB 後
  // 透過 API 從 Server 取得「商家設定」、「點數累積方法」、「所有預約歷史」、「所有可預約項目」、以及「所有可兌換優惠」
  useEffect(() => {
    if (setupStoreDBName) {
      fetch(`${SERVER_BASE_URL}/api/get-all-lists/${storeDB}`)
        .then(res => res.json())
        .then(data => {

          // 將抓到的存在 Redux
          dispatch({ type: 'SET_POINT_METHODS', payload: data.pointMethod });
          dispatch({ type: 'SET_APPOINTMENT_LISTS', payload: data.appointmentList });
          dispatch({ type: 'SET_COUPON_LIST', payload: data.couponList });
          dispatch({ type: 'SET_STORE_SETTING', payload: data.storeSetting });
          dispatch({ type: 'SET_APPOINTMENT_HISTORY', payload: data.appointmentHistory });

          setStoreName(data.storeSetting[0].storeName);
          setMainColorStyle(data.storeSetting[0].mainColorStyle);
          setSubColorStyle(data.storeSetting[0].subColorStyle);
          setLiffId(data.storeSetting[0].liffId);
          setLiffIdisSeted(true);
        })
    }
  }, [setupStoreDBName]);
  // End


  // 3. 在上一步設定了 liffId
  // 這步從liff拿資料
  useEffect(() => {
    if (liffIdisSeted) {
      liff.init({
        liffId: liffId, // 每一個商家的 liff ID 不一樣，自己有自己的 liff ID
        withLoginOnExternalBrowser: true, // Debug用，照理來說要為false
      }).then(() => {
        if (!liff.isLoggedIn()) {
          liff.login({
            // redirectUri: location.pathname
            // redirectUri: 'https://weiz-doorsystem.site/?storeDB=testClient'
          })
        }
        else {
          liff.getProfile().then(profile => {
            console.log("userID: ", profile.userId);
            dispatch({ type: 'SET_USER_ID', payload: profile.userId });
            dispatch({ type: 'SET_PICTURE_URL', payload: profile.pictureUrl });
            setTmpDisplayName(profile.displayName);
            setLiffStatus(true);
          })
        }
      });
    }
  }, [liffId]);
  // End

  // 4. 當 liff 也 init 完（拿到 userId 之後）
  // 根據line userId，判斷該用戶是否已經是會員
  // 若是，則設 userExisted 為 true
  // 若否，則進行手機驗證（顯示 OTPAuth 彈跳視窗）
  useEffect(() => {
    if (liffStatus && setupStoreDBName) {
      fetch(`${SERVER_BASE_URL}/api/get-user-info/${storeDB}/${userId}`)
        .then(res => res.json())
        .then(data => {
          if (data.status === '404') {
            setFirstTimeVisit(true);
          }
          setUserExisted(true);
        })
    }
  }, [liffStatus, setupStoreDBName]); // 偵測: 當liff載入完成後執行 (Actually, 一開始會先執行一次，但當liffStatus變動後再執行一次)
  // End


  // 5. 當使用者已經是會員
  // 到 DB 拿「該 user 所有歷史預約記錄」和「該 user 所有兌換紀錄」
  // 並在將他的個人資料存到 Redux 中
  useEffect(() => {
    setFetchFromDB(true);
    if (userExisted) {
      fetch(`${SERVER_BASE_URL}/api/get-all-history/${storeDB}/${userId}`)
        .then(res => res.json())
        .then(data => {
          dispatch({ type: 'SET_SELF_APPOINTMENT_HISTORY', payload: data.selfAppointmentHistory });
          dispatch({ type: 'SET_COUPON_HISTORY', payload: data.selfCouponHistory });
          setFetchFromDB(true);
        });
      fetch(`${SERVER_BASE_URL}/api/get-user-info/${storeDB}/${userId}`)
        .then(res => res.json())
        .then(data => {
          dispatch({ type: 'SET_DISPLAY_NAME', payload: data.displayName });
          dispatch({ type: 'SET_PHONE', payload: data.phone });
          dispatch({ type: 'SET_POINTS', payload: data.points });
          dispatch({ type: 'SET_INVITE_CODE', payload: data.inviteCode });
          dispatch({ type: 'SET_USE_INVITE_CODE', payload: data.useInviteCode });
        });
    }
    setFetchFromDB(false);
  }, [userExisted]);

  useEffect(() => {
    setFetchFromDB(true);
    if (userExisted) {
      fetch(`${SERVER_BASE_URL}/api/get-store-partners/${storeDB}`)
        .then(res => res.json())
        .then(data => {
          dispatch({ type: 'SET_STORE_PARTNERS', payload: data });
        })
    }
    setFetchFromDB(false);
  }, [userExisted])

  // 每次加载页面时检查当前路径是否为根路径，如果不是，则强制跳转到根路径
  useEffect(() => {
    if (location.pathname !== '/') {
      navigate('/');
    }
  }, []);
  // End

  return (
    <div>
      <Header storeName={storeName} mainColorStyle={mainColorStyle} subColorStyle={subColorStyle} />
      <Toaster />

      {firstTimeVisit && <OTPAuth firstTimeVisit={firstTimeVisit} setFirstTimeVisit={setFirstTimeVisit} userId={userId} pictureUrl={pictureUrl} displayName={tmpDisplayName} />}

      <div className="app-container">
        {
          !liffStatus || !fetchFromDB ? // 只要liff還沒準備好 或 還沒從DB拿完資料，則畫面轉圈
            <div className="spinner-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
            :
            (
              <div style={{ paddingBottom: '20%' }}> {/* 為了讓Footer不要擋住body內容 */}
                <Routes>
                  <Route index element={<MemberMain />} />
                  <Route path="/edit-profile" element={<EditProfilePage />} />
                  <Route path="/appointment" element={<Appointment />} />
                  <Route path="/appointment-list" element={<AppointmentList />} />
                  <Route path="/coupon" element={<Coupon />} />
                  <Route path='/coupon-detail' element={<CouponDetail />} />
                  <Route path='/appointment-date' element={<DateTimeModel />} />
                  <Route path='/appointment-confirm' element={<AppointmentConfirm />} />
                  <Route path='/appointment-detail' element={<AppointmentDetail />} />
                  <Route path='/add-invite-code' element={<AddInviteCode />} />
                  <Route path='/select-partner' element={<SelectPartner />} />
                </Routes>
              </div>
            )
        }
      </div>
      <Footer mainColorStyle={mainColorStyle} subColorStyle={subColorStyle} />
    </div>
  );
}

export default App;
