import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Button, Modal } from 'react-bootstrap';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { IoMdArrowRoundBack } from 'react-icons/io';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const DateTimeModel = () => {
    const navigate = useNavigate();

    const storeDB = useSelector(state => state.storeDB);
    const storeSetting = useSelector(state => state.storeSetting[0]);
    const appointmentHistory = useSelector(state => state.appointmentHistory);
    const mainColorStyle = storeSetting.mainColorStyle;
    const subColorStyle = storeSetting.subColorStyle;

    const openServiceTime = storeSetting.openServiceTime;
    const closeServiceTime = storeSetting.closeServiceTime;
    const serviceTimeInterval = storeSetting.serviceTimeInterval;


    // 上一頁傳來的預約資訊：預約什麼項目？會花多久？多少元起？
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const partnerId = searchParams.get('partnerId');
    const title = searchParams.get('appointmentTitle');
    const price = searchParams.get('appointmentPrice');
    const takeTime = searchParams.get('appointmentTakeTime');
    const remark = decodeURIComponent(searchParams.get('appointmentRemark'));

    const [availableTimes, setAvailableTimes] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');
    const [selectedTime, setSelectedTime] = useState('');
    const [showModal, setShowModal] = useState(false);

    const [forceTimeList, setForceTimeList] = useState([]);

    const [isSetStoreDB, setIsSetStoreDB] = useState(false);

    const partnerAppointmentHistory = appointmentHistory.filter(appointment => appointment.partner === partnerId)
    console.log(partnerAppointmentHistory);

    useEffect(() => {
        if (storeDB) {
            console.log("DTM.js 46: ", storeDB);
            setIsSetStoreDB(true);
        }
    }, [storeDB]);


    useEffect(() => {
        if (isSetStoreDB) {
            fetch(`${SERVER_BASE_URL}/api/get-all-force-open-close-date-time/${storeDB}`)
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                    setForceTimeList(data);
                })
        }
    }, [isSetStoreDB]);


    const handleDateChange = (date) => {
        fetchAvailableTimes(date);
    };


    // 一次先產生所有時段
    // 從「開始接受預約時間」到「最後可接受預約時間」的所有時間段（用 serviceTimeInterval 來分割時間段）
    // 例如每30分鐘切一個時間段
    const generateTimeArray = (startTime, endTime, interval) => {
        const result = [];
        let currentTime = dayjs(startTime, 'HH:mm');

        while (currentTime.isBefore(dayjs(endTime, 'HH:mm'))) {
            result.push(currentTime.format('HH:mm'));
            currentTime = currentTime.add(interval, 'minute');
        }

        result.push(dayjs(endTime, 'HH:mm').format('HH:mm')); // 添加最后一个时间
        return result;
    };

    // 該預約往前扣除時間
    // 例如: 11:00 有一個服務A預約，且服務A會花90分鐘(takeTime = 90)
    // 則，11:00往前  [ takeTime / serviceTimeInterval(假設為30)] 次計算
    // 得到：10:00, 10:30都不提供預約，
    // 因為 10:00 開始會做到 11:30，與 11:00 的預約衝突
    const forwardFilter = (availableTimes, appointmentsOnDate) => {
        const bookTimeList = appointmentsOnDate.flatMap(appointment => {
            const res = [];
            res.push(appointment.bookTime);
            return res;
        });


        const remainderTime = [];
        for (let i = 0; i < bookTimeList.length; i++) {
            let currentTime = dayjs(bookTimeList[i], "HH:mm");
            for (let j = 1; j < takeTime / serviceTimeInterval; j++) {
                currentTime = currentTime.subtract(serviceTimeInterval, "minute");
                remainderTime.push(currentTime.format("HH:mm"));
            }
        }

        return (availableTimes.filter(time => !remainderTime.includes(time)));
    }


    const fetchAvailableTimes = (date) => {
        const timeArray = generateTimeArray(openServiceTime, closeServiceTime, serviceTimeInterval); // 先產生所有時段
        const dateString = dayjs(date).format('YYYY-MM-DD'); // 選定日期
        setSelectedDate(dateString);

        const appointmentsOnDate = partnerAppointmentHistory.filter(appointment => appointment.bookDate === dateString); // 找到今天日期的所有預約

        const forceTimeOnDate = forceTimeList.filter(record => record.bookDate === dateString && record.partner === partnerId);

        let forceCloseDateTime = [];
        let forceOpenDateTime = [];
        for (let i = 0; i < forceTimeOnDate.length; i++) {
            if (forceTimeOnDate[i].action === 'close' && forceTimeOnDate[i].booked === 'false') {
                forceCloseDateTime.push(forceTimeOnDate[i].bookTime);
            }
            else if (forceTimeOnDate[i].action === 'open' && forceTimeOnDate[i].booked === 'false') {
                forceOpenDateTime.push(forceTimeOnDate[i].bookTime);
            }
        }

        const timeArrayWithTakeTime = appointmentsOnDate.flatMap(appointment => {
            const start = dayjs(appointment.bookTime, 'HH:mm');
            const end = start.add(appointment.takeTime, 'minute');
            const result = [];

            let currentTime = start;
            while (currentTime.isBefore(end)) {
                result.push(currentTime.format('HH:mm'));
                currentTime = currentTime.add(serviceTimeInterval, 'minute');
            }

            return result;
        });

        const availableTimes = timeArray.filter(time => !timeArrayWithTakeTime.includes(time));

        const finalAvailableTimes = forwardFilter(availableTimes, appointmentsOnDate);

        // 將「被強制關閉的時間」從 finalAvailableTimes 移出
        const filteredFinalAvailableTimes = finalAvailableTimes.filter(time => !forceCloseDateTime.includes(time));

        // 再將「被強制開放的時間」加進 filteredFinalAvailableTimes        
        forceOpenDateTime.forEach(time => {
            if (!filteredFinalAvailableTimes.includes(time)) {
                filteredFinalAvailableTimes.push(time);
            }
        });




        console.log(filteredFinalAvailableTimes);

        // 最後「若是當天」，移除「比預約當下」還早的時段
        const todayNow = new Date();
        const year = todayNow.getFullYear();
        const month = String(todayNow.getMonth() + 1).padStart(2, '0'); // 月份从0开始计数，因此需要加1，并确保两位数
        const day = String(todayNow.getDate()).padStart(2, '0'); // 确保两位数
        const hours = String(todayNow.getHours()).padStart(2, '0');
        const minutes = String(todayNow.getMinutes()).padStart(2, '0');
        const formattedDate = `${year}-${month}-${day}`; // 今天日期
        const formattedTime = `${hours}:${minutes}`; // 現在時間

        if (formattedDate === dateString) {
            console.log("同一天");

            // 过滤掉小于当前时间的元素
            const filteredTimes = filteredFinalAvailableTimes.filter(time => time > formattedTime);

            setAvailableTimes(filteredTimes.sort());
        } else {
            setAvailableTimes(filteredFinalAvailableTimes.sort());
        }

        // setAvailableTimes(filteredFinalAvailableTimes.sort());
    };

    const shouldDisableDate = (date) => {
        const today = new Date();
        const selectedDate = new Date(date);

        const dayOfWeek = selectedDate.getDay();

        const holidayMapping = {
            "Weekend": 0,
            "Mon": 1,
            "Tue": 2,
            "Wed": 3,
            "Thr": 4,
            "Fri": 5,
            "Sat": 6,
            "Sun": 7,
            "Manual": 8
        };

        const holiday = storeSetting.holiday;
        const holidayNumber = holidayMapping[holiday];

        // 計算一個月後的日期
        const oneMonthLater = new Date();
        oneMonthLater.setMonth(today.getMonth() + 1);
        oneMonthLater.setHours(0, 0, 0, 0);

        // 檢查選定日期是否超過一個月後
        const isBeyondOneMonth = selectedDate.getTime() > oneMonthLater.getTime();

        // 禁用今天的日期
        const isToday = selectedDate.setHours(0, 0, 0, 0) === today.setHours(0, 0, 0, 0);

        if (holidayNumber === 0) {
            return dayOfWeek === 0 || dayOfWeek === 6 || isBeyondOneMonth || isToday;
        }
        else if (holidayNumber === 8) {
            return isBeyondOneMonth || isToday;
        }
        else {
            // 回傳需要被 disable 的日期，包含：
            // - 今天以前的日期
            // - 商家休息日
            // - 超過一個月後的日期
            return dayOfWeek === holidayNumber || isBeyondOneMonth || isToday;
        };
    }


    const handleNextStep = (time) => {
        setSelectedTime(time);
        setShowModal(true); // 点击按钮后显示 Modal
    };

    const handleModalClose = () => {
        setShowModal(false); // 关闭 Modal
    };

    const handleConfirmNextStep = (date, time) => {
        // 处理下一步操作逻辑
        // 这里可以添加你需要的逻辑代码
        setShowModal(false); // 关闭 Modal
        console.log(date, time);
        navigate(`/appointment-confirm?partnerId=${partnerId}&date=${date}&time=${time}&title=${title}&price=${price}&takeTime=${takeTime}&remark=${encodeURIComponent(remark)}`);
    };

    return (
        <>
            {!isSetStoreDB && (
                <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}

            <IoMdArrowRoundBack size={30} onClick={() => navigate(-1)} style={{ color: mainColorStyle, marginTop: '30px', marginLeft: '30px' }} />
            <Container className='d-flex flex-column justify-content-center align-items-center' style={{ width: '90%', marginTop: "20px" }}>
                <p style={{ color: mainColorStyle, fontFamily: 'Fraunces, Arial, sans-serif', fontWeight: 'bold', fontSize: '18px' }}>選擇日期與時間</p>
            </Container>
            <Container style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', borderColor: mainColorStyle }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateCalendar
                        onChange={handleDateChange}
                        disablePast={true}
                        shouldDisableDate={shouldDisableDate}
                        // view='day'
                        views={['day']}
                    />
                </LocalizationProvider>
            </Container>

            {
                availableTimes.length === 0 && (
                    <Container className='d-flex flex-column justify-content-center align-items-center' style={{ width: '80%' }}>
                        <div style={{ paddingBottom: '10vh' }}>
                            <div className="d-flex justify-content-center align-items-center flex-wrap" style={{ width: '100%' }}>
                                <span style={{ color: mainColorStyle, fontWeight: 'bold' }}>本日已約滿</span>
                            </div>
                        </div>
                    </Container>
                )
            }

            {
                availableTimes.length > 0 && (
                    <Container className='d-flex flex-column justify-content-center align-items-center' style={{ width: '80%' }}>
                        <div style={{ paddingBottom: '10vh' }}>
                            <div className="d-flex justify-content-center align-items-center flex-wrap" style={{ width: '100%' }}>
                                <span style={{ color: mainColorStyle, fontWeight: 'bold' }}>請選擇時段: </span>
                            </div>
                            <div className="d-flex justify-content-center align-items-center flex-wrap" style={{ width: '100%' }}>
                                {availableTimes.map((time, index) => (
                                    <Button key={index} variant="primary" onClick={() => { handleNextStep(time) }} style={{ width: '20vw', marginLeft: '5px', marginTop: '10px', fontFamily: 'Fraunces, Arial, sans-serif', backgroundColor: mainColorStyle, borderColor: 'transparent', color: '#fff' }}>{time}</Button>
                                ))}
                            </div>
                        </div>
                    </Container>
                )
            }

            <Modal show={showModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title><span style={{ color: mainColorStyle, fontFamily: 'Fraunces, Arial, sans-serif' }}>前往下一步</span></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ color: mainColorStyle }}>
                    <span style={{ fontFamily: 'Fraunces, Arial, sans-serif' }}>您選擇的時間</span>
                    <br />
                    <span style={{ fontFamily: 'Fraunces, Arial, sans-serif' }}>日期: </span>{selectedDate}
                    <br />
                    <span style={{ fontFamily: 'Fraunces, Arial, sans-serif' }}>時間: </span>{selectedTime}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose} style={{ fontFamily: 'Fraunces, Arial, sans-serif', borderColor: mainColorStyle, backgroundColor: 'transparent', color: mainColorStyle }}>
                        取消
                    </Button>
                    <Button variant="primary" onClick={() => { handleConfirmNextStep(selectedDate, selectedTime); }} style={{ fontFamily: 'Fraunces, Arial, sans-serif', backgroundColor: mainColorStyle, borderColor: 'transparent', color: '#fff' }}>
                        下一步
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DateTimeModel;