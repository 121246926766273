import { useState } from "react";
import { useSelector } from 'react-redux';
import { Container, Nav } from 'react-bootstrap';
import Avatar from "../Components/UserInfo/Avatar";
import UserName from "../Components/UserInfo/UserName";
import InviteCode from "../Components/UserInfo/InviteCode";
import CommonBtn from "../Components/CommonBtn/CommonBtn";

import CouponList from "../Components/SubCoupon/CouponList";
import CouponHistory from "../Components/SubCoupon/CouponHistory";

const Coupon = () => {
    const displayName = useSelector(state => state.displayName);
    const pictureUrl = useSelector(state => state.pictureUrl);
    const points = useSelector(state => state.points);
    const inviteCode = useSelector(state => state.inviteCode);

    const storeSetting = useSelector(state => state.storeSetting[0]);
    const mainColorStyle = storeSetting.mainColorStyle;
    const subColorStyle = storeSetting.subColorStyle;

    const [activeKey, setActiveKey] = useState("coupon-list");

    const handleSelect = (eventKey) => {
        setActiveKey(eventKey);
    };

    return (
        <>
            <Avatar imageUrl={pictureUrl} />
            <UserName username={displayName} />
            <InviteCode code={inviteCode} />
            <CommonBtn points={points} />

            <Container className='d-flex flex-column justify-content-center align-items-center' style={{ width: '90%', marginTop: "20px", paddingBottom: "10vh" }}>
                <Nav variant="underline" defaultActiveKey={activeKey} onSelect={handleSelect}>
                    <Nav.Item>
                        <Nav.Link className="navLink" eventKey="coupon-list"><span style={{ color: mainColorStyle, fontFamily: 'Fraunces, Arial, sans-serif' }}>優惠項目</span></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link className="navLink" eventKey="coupon-history"><span style={{ color: mainColorStyle, fontFamily: 'Fraunces, Arial, sans-serif' }}>歷史兌換</span></Nav.Link>
                    </Nav.Item>
                </Nav>

                {activeKey === "coupon-list" && <CouponList />}
                {activeKey === "coupon-history" && <CouponHistory />}
            </Container>
        </>
    );
}

export default Coupon;