import { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Avatar from "../Components/UserInfo/Avatar";
import { Container, Button } from "react-bootstrap";
import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const EditProfilePage = () => {

    const dispatch = useDispatch();
    const storeDB = useSelector(state => state.storeDB);
    const userId = useSelector(state => state.userId);
    const displayName = useSelector(state => state.displayName);
    const pictureUrl = useSelector(state => state.pictureUrl);
    const phone = useSelector(state => state.phone);
    const useInviteCode = useSelector(state => state.useInviteCode);

    const storeSetting = useSelector(state => state.storeSetting[0]);
    const mainColorStyle = storeSetting.mainColorStyle;
    const subColorStyle = storeSetting.subColorStyle;

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);


    const [tempDisplayName, setTempDisplayName] = useState(displayName);
    const [tempPhone, setTempPhone] = useState(phone);
    const [tmpUseInviteCode, setTmpUseInviteCode] = useState(useInviteCode);


    const submitNotify = () => toast.success('儲存成功', {
        duration: 5000,
    });

    const submitBtn = async () => {
        try {
            setIsLoading(true);
            const response = await fetch(`${SERVER_BASE_URL}/api/${storeDB}/${userId}`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    displayName: tempDisplayName,
                })
            })
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            } else {
                dispatch({ type: 'SET_DISPLAY_NAME', payload: tempDisplayName });
                dispatch({ type: 'SET_USE_INVITE_CODE', payload: tmpUseInviteCode });
                setIsLoading(false);
                navigate(-1);
            }
        }
        catch (error) {
            setIsLoading(false);
            alert('A problem occurred with your fetch operation: ', error);
            console.error('A problem occurred with your fetch operation: ', error);
        }
    }

    const navigateToChangeInviteCode = () => {
        // 待改
        // navigate('/add-invite-code');
    }

    // 在進入頁面時滾動視窗到頂部
    useEffect(() => {
        const timeout = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 100); // 延遲 100 毫秒後滾動

        return () => clearTimeout(timeout);
    }, []);

    return (
        <>
            {isLoading && (
                <div style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: '9999', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            )}
            <Container>
                <IoMdArrowRoundBack size={30} onClick={() => navigate(-1)} style={{ color: mainColorStyle, marginTop: '30px', marginLeft: '30px' }} />
                <Avatar imageUrl={pictureUrl} />
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <InputGroup className="mb-3" style={{ width: '80vw' }}>
                        <InputGroup.Text id="inputGroup-userID" style={{ border: 'none', width: '20vw', backgroundColor: '#FFF' }}>
                            <span style={{ color: mainColorStyle }}>user ID</span>
                        </InputGroup.Text>
                        <Form.Control
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            value={userId}
                            style={{ pointerEvents: 'none', color: mainColorStyle, border: 'none', borderBottom: '0.1px solid rgba(0, 0, 0, 0.5)', borderBottomLeftRadius: '0%', borderBottomRightRadius: '0%' }}
                            readOnly={true}
                        />
                    </InputGroup>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <InputGroup className="mb-3" style={{ width: '80vw' }}>
                        <InputGroup.Text id="inputGroup-displayName" style={{ width: '20vw', border: 'none', backgroundColor: '#FFF' }}>
                            <span style={{ color: mainColorStyle }}>名稱</span>
                        </InputGroup.Text>

                        <Form.Control
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            value={tempDisplayName}
                            className="shadow-none"
                            style={{ outline: 'none', color: mainColorStyle, border: 'none', borderBottom: '0.1px solid rgba(0, 0, 0, 0.5)', borderBottomLeftRadius: '0%', borderBottomRightRadius: '0%' }}
                            onChange={(e) => setTempDisplayName(e.target.value)}
                        />
                    </InputGroup>

                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <InputGroup className="mb-3" style={{ width: '80vw' }}>
                        <InputGroup.Text id="inputGroup-phone" style={{ border: 'none', width: '20vw', backgroundColor: '#FFF' }}>
                            <span style={{ color: mainColorStyle }}>電話</span>
                        </InputGroup.Text>
                        <Form.Control
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            value={tempPhone}
                            type="number"
                            className="shadow-none"
                            style={{ color: mainColorStyle, border: 'none', borderBottom: '0.1px solid rgba(0, 0, 0, 0.5)', borderBottomLeftRadius: '0%', borderBottomRightRadius: '0%' }}
                            onChange={(e) => setTempPhone(e.target.value)}
                            readOnly={true}
                        />
                    </InputGroup>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                    <InputGroup className="mb-3" style={{ width: '80vw' }}>
                        <InputGroup.Text id="inputGroup-InvitedCode" style={{ width: '20vw', border: 'none', backgroundColor: '#FFF' }}>
                            <span style={{ color: mainColorStyle }}>邀請碼</span>
                        </InputGroup.Text>

                        {/* <Button
                            variant="outline-secondary"
                            className="shadow-none"
                            style={{ backgroundColor: 'transparent', outline: 'none', color: '#0D7A7B', border: 'none', borderBottom: '0.1px solid rgba(0, 0, 0, 0.5)', borderBottomLeftRadius: '0%', borderBottomRightRadius: '0%' }}
                        // onClick={handleClick}
                        >
                            {useInviteCode}
                        </Button> */}

                        <Form.Control
                            as={Button}
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            // value={useInviteCode}
                            className="shadow-none"
                            style={{ textAlign: 'left', backgroundColor: 'transparent', outline: 'none', color: mainColorStyle, border: 'none', borderBottom: '0.1px solid rgba(0, 0, 0, 0.5)', borderBottomLeftRadius: '0%', borderBottomRightRadius: '0%' }}
                            onClick={navigateToChangeInviteCode}
                            readOnly={true}
                        >
                            {useInviteCode}
                        </Form.Control>
                    </InputGroup>

                </div>
                <Container className='d-flex flex-column justify-content-center align-items-center' style={{ marginTop: "30px", marginBottom: "100px" }}>
                    <Button onClick={() => { submitBtn(); submitNotify(); }} className="py-2 rounded-pill" variant="primary" style={{ borderColor: 'transparent', width: '80vw', backgroundColor: mainColorStyle }}><span style={{ color: subColorStyle, fontWeight: 'bold', fontFamily: 'Fraunces, Arial, sans-serif' }}>儲存修改</span></Button>
                </Container>
            </Container>
        </>
    );
}

export default EditProfilePage;