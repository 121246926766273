import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import crypto from 'crypto-js';
import { Container, Navbar, Button, Modal } from 'react-bootstrap';
import { LuMousePointerClick } from "react-icons/lu";
import Spinner from 'react-bootstrap/Spinner';
import toast from 'react-hot-toast';

import flexModel from '../FlexModel.json';

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const CouponList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const storeDB = useSelector(state => state.storeDB);
    const navbarsData = useSelector(state => state.couponList);
    const userId = useSelector(state => state.userId);
    const points = useSelector(state => state.points);
    const storeSetting = useSelector(state => state.storeSetting[0]);
    const mainColorStyle = storeSetting.mainColorStyle;
    const subColorStyle = storeSetting.subColorStyle;

    const [dbProcessing, setDbProcessing] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedCoupon, setSelectedCoupon] = useState({});

    const handleModalClose = () => {
        setShowModal(false); // 关闭 Modal
    };

    const exchangeCoupon = (title, pointCost) => {
        setSelectedCoupon({ title, pointCost });
        setShowModal(true);
    }

    const generateRandomCode = (title) => {
        const timestamp = Math.floor(Date.now() / 1000); // 获取当前时间戳（精确到秒）
        const inputString = `${timestamp}-${title}`; // 将当前时间戳与要加入的字符串进行拼接
        const hashValue = crypto.MD5(inputString).toString(); // 计算 MD5 哈希值

        // 截取哈希值的一部分作为随机码
        const randomCodePart = hashValue.substring(0, 10).toUpperCase(); // 截取前 10 位并转换为大写

        return randomCodePart; // 设置随机码
    };

    const navigateToCouponDetail = (exchangeId, title, pointCost, currentTime) => {
        navigate(`/coupon-detail?exchangeId=${exchangeId}&title=${title}&pointCost=${pointCost}&currentTime=${currentTime}`);
    }


    const generateJson = (params) => {
        // 將模板中的佔位符替換為參數值
        const jsonString = JSON.stringify(flexModel)
            .replace('{{emoji}}', params.emoji)
            .replace('{{eventTitle}}', params.eventTitle)
            .replace('{{currentTime}}', params.currentTime)
            .replace('{{eventDescription}}', params.eventDescription)
            .replace('{{eventPrice}}', params.eventPrice)
        // .replace('{{eventTakeTime}}', params.eventTakeTime)
        // .replace('{{eventRemark}}', params.eventRemark)
        // .replace('{{eventCommentToStore}}', params.eventCommentToStore);

        return JSON.parse(jsonString);
    };

    const confirmExchange = (title, pointCost) => {
        if (parseInt(points, 10) < parseInt(pointCost, 10)) {
            setShowModal(false);
            alert('點數不夠!');
        }
        else {
            setDbProcessing(true);

            // 目前時間
            const now = new Date();
            const year = now.getFullYear();
            const month = (now.getMonth() + 1).toString().padStart(2, '0'); // 月份从 0 开始，需要 +1
            const day = now.getDate().toString().padStart(2, '0');
            const hours = now.getHours().toString().padStart(2, '0');
            const minutes = now.getMinutes().toString().padStart(2, '0');
            const seconds = now.getSeconds().toString().padStart(2, '0');
            const formattedTime = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
            // End

            // 兌換id
            const exhangeId = generateRandomCode(title);
            // End

            const re_points = parseInt(points, 10) - parseInt(pointCost, 10); // 剩餘點數

            // 新增兌換紀錄到「兌換歷史」表單中
            fetch(`${SERVER_BASE_URL}/api/add-coupon-history/${storeDB}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
                body: JSON.stringify({
                    "id": exhangeId,
                    "userId": userId,
                    "title": title,
                    "pointCost": pointCost,
                    "currentTime": formattedTime
                })
            })
                .then(res => res.json())
                .then(data => {
                    // 新增成功後，修改該userId之「點數餘額」
                    dispatch({ type: 'SET_COUPON_HISTORY', payload: data });

                    fetch(`${SERVER_BASE_URL}/api/${storeDB}/${userId}`, {
                        method: "PATCH",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            points: re_points
                        })
                    })
                        .then(res => res.json())
                        .then(data => {
                            dispatch({ type: 'SET_POINTS', payload: data.points });

                            const params = {
                                emoji: '🎫',
                                eventTitle: "成功兌換優惠",
                                currentTime: `於 ${formattedTime} 兌換`,
                                eventDescription: `您已成功兌換 ${title}`,
                                eventPrice: `花費 ${pointCost}點，剩餘 ${re_points}`,
                                // eventTakeTime: `服務時間大約 ${takeTime}分鐘`,
                                // eventRemark: `此服務注意事項: ${remark}`,
                                // eventCommentToStore: `給店家的備註: ${comment}`
                            };
                            const pushFlex = generateJson(params);
                            const encodedChannelAccessToken = encodeURIComponent(storeSetting.channelAccessToken);
                            const encodedUserId = encodeURIComponent(userId);
                            fetch(`${SERVER_BASE_URL}/api/push-line-flex-message/${encodedChannelAccessToken}/${encodedUserId}`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({
                                    messages: pushFlex,
                                }),
                            })
                                .then(response => response.json())
                                .then(data => {
                                    console.log('Message sent:', data);
                                })
                                .catch(error => {
                                    console.error('Error sending message:', error);
                                });


                            setDbProcessing(false);
                            toast.success('兌換成功', {
                                duration: 5000,
                            });
                        })


                })
            setShowModal(false);
            navigateToCouponDetail(exhangeId, title, pointCost, formattedTime);
        }
    }

    return (
        <>
            {dbProcessing ?
                (
                    <div className="spinner-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                )
                :
                (
                    <Container className='justify-content-center' style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <p style={{ color: mainColorStyle, textAlign: 'center' }}>請在結帳當下兌換並主動出示結果</p>
                        {navbarsData.map(navbar => (
                            <Navbar key={navbar.title} as={Button} onClick={() => { exchangeCoupon(navbar.title, navbar.points) }} style={{ marginTop: '10px', borderColor: 'rgba(44, 35, 81, 0.2)', width: '100%', backgroundColor: 'transparent', borderRadius: '10px', display: 'flex', alignItems: 'center' }}>
                                <div style={{ marginLeft: '10px', flex: '20%', color: mainColorStyle, textAlign: 'left' }}><span style={{ fontSize: '20px' }}>{navbar.points}</span><span style={{ fontSize: '12px' }}> 點</span></div>
                                <div style={{ flex: '60%', color: mainColorStyle, textAlign: 'center', }}>{navbar.title}</div>
                                <div style={{ marginRight: '10px', flex: '20%', color: mainColorStyle, textAlign: 'right' }}>
                                    <LuMousePointerClick />
                                </div>
                            </Navbar>
                        ))}
                    </Container>
                )
            }


            <Modal show={showModal} onHide={handleModalClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title><span style={{ color: mainColorStyle, fontFamily: 'Fraunces, Arial, sans-serif' }}>確定兌換</span></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ color: mainColorStyle }}>
                    <span style={{ fontFamily: 'Fraunces, Arial, sans-serif' }}>請確認服務人員知道您要兌換此優惠</span>
                    <br />
                    <br />
                    <span style={{ fontFamily: 'Fraunces, Arial, sans-serif' }}>兌換項目: </span>{selectedCoupon.title}
                    <br />
                    <span style={{ fontFamily: 'Fraunces, Arial, sans-serif' }}>花費點數: </span>{selectedCoupon.pointCost} 點
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleModalClose} style={{ fontFamily: 'Fraunces, Arial, sans-serif', borderColor: mainColorStyle, backgroundColor: 'transparent', color: mainColorStyle }}>
                        取消
                    </Button>
                    <Button variant="primary" onClick={() => confirmExchange(selectedCoupon.title, selectedCoupon.pointCost)} style={{ fontFamily: 'Fraunces, Arial, sans-serif', backgroundColor: mainColorStyle, borderColor: 'transparent', color: '#fff' }}>
                        確定
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default CouponList;