import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Navbar, Button } from 'react-bootstrap';
import { FaAngleRight } from "react-icons/fa6";
import { IoTimeOutline } from "react-icons/io5";
import { IoMdArrowRoundBack } from 'react-icons/io';

const AppointmentList = () => {
    const storeSetting = useSelector(state => state.storeSetting[0]);
    const mainColorStyle = storeSetting.mainColorStyle;
    const subColorStyle = storeSetting.subColorStyle;

    const navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const partnerId = searchParams.get('partnerId');
    const storePartners = useSelector(state => state.storePartners);
    const partner = storePartners.find(p => p.id === partnerId);
    const partnerUsername = partner ? partner.username : 'Unknown Partner';

    const navbarsData = useSelector(state => state.appointmentList);
    const filteredData = navbarsData.filter(navbar => navbar.partner === partnerId);

    const navigateToCalendar = (navbar) => {
        navigate(`/appointment-date?partnerId=${partnerId}&appointmentTitle=${navbar.title}&appointmentTakeTime=${navbar.takeTime}&appointmentPrice=${navbar.price}&appointmentRemark=${encodeURIComponent(navbar.remark)}`);
    }

    return (
        <>
            <IoMdArrowRoundBack size={30} onClick={() => navigate(-1)} style={{ color: mainColorStyle, marginTop: '30px', marginLeft: '30px' }} />
            <Container className='justify-content-center' style={{ marginTop: '20px', marginBottom: '20px', width: '80vw' }}>
                <span style={{ fontFamily: 'Fraunces, Arial, sans-serif', color: mainColorStyle }}>{partnerUsername} 提供的服務</span>
            </Container>
            <Container className='justify-content-center' style={{ marginTop: '20px', marginBottom: '20px', width: '80vw' }}>
                {filteredData.map(navbar => (
                    <Navbar key={navbar.title} as={Button} onClick={() => { navigateToCalendar(navbar) }} style={{ marginTop: '10px', borderColor: '#2C2351', width: '100%', backgroundColor: 'transparent', borderRadius: '10px' }}>
                        <Container className="d-flex align-items-center">
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <div style={{ flex: '0 0 70%', marginBottom: '5px', textAlign: 'left' }}>
                                    <Navbar.Brand style={{ color: '#fff', fontSize: '16px', fontFamily: 'Fraunces, Arial, sans-serif', whiteSpace: 'normal' }}>
                                        <span style={{ color: mainColorStyle }}>{navbar.title}</span>
                                    </Navbar.Brand>
                                </div>
                                <div style={{ flex: '0 0 30%', display: 'flex', alignItems: 'center' }}>
                                    <IoTimeOutline color={subColorStyle} size={10} /> <span style={{ fontSize: '12px', color: subColorStyle }}>{navbar.takeTime}min / ${navbar.price}元起</span>
                                </div>
                            </div>
                            <Navbar.Collapse className="justify-content-end">
                                <FaAngleRight color={mainColorStyle} />
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                ))}
            </Container>
        </>
    );
}

export default AppointmentList;