import { useState, useEffect } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { nanoid } from 'nanoid';

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;


const OTPAuth = ({ firstTimeVisit, setFirstTimeVisit, userId, pictureUrl, displayName }) => {
    const storeDB = useSelector(state => state.storeDB);

    const [phoneNum, setPhoneNum] = useState('');

    const [isOTPSent, setIsOTPSent] = useState(false);
    const [countdown, setCountdown] = useState(60);
    const [otp, setOtp] = useState('');


    // 當 OTP 驗證碼發送後才觸發
    // 60 秒倒數計時器
    useEffect(() => {
        let timer;
        if (isOTPSent && countdown > 0) {
            timer = setInterval(() => {
                setCountdown(prevCountdown => prevCountdown - 1);
            }, 1000);
        }
        if (countdown === 0) {
            setIsOTPSent(false);
        }
        return () => clearInterval(timer);
    }, [isOTPSent, countdown]);

    const changePhoneNum = (event) => {
        setPhoneNum(event.target.value);
    }


    const handleSendOTP = () => {
        const phoneRegex = /^09\d{8}$/;

        if (!phoneRegex.test(phoneNum)) {
            alert('手機格式不對');
            return;
        }

        setIsOTPSent(true);
        setCountdown(60);
        sendOTP();
    };

    const handleOTPChange = (e) => {
        setOtp(e.target.value);
    };


    const sendOTP = () => {
        if (!/^(09\d{8})$/.test(phoneNum)) {
            alert('請輸入有效的手機號碼。必須以09開頭且為10碼數字。');
            return;
        }
        // console.log(phoneNum);
        // setFirstTimeVisit(false);
        console.log(phoneNum);
        fetch(`${SERVER_BASE_URL}/api/send-otp`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                "phoneNum": phoneNum
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);

            })
    }

    const verifyOTP = () => {
        fetch(`${SERVER_BASE_URL}/api/verify-otp`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                phoneNum: phoneNum,
                otp: otp
            })
        })
            .then(res => res.json())
            .then(data => {
                if (data.success) {
                    toast.success('驗證成功');
                    fetch(`${SERVER_BASE_URL}/api/createUser/${storeDB}`, {
                        method: 'POST',
                        headers: {
                            "Content-Type": "application/json",
                            "Accept": "application/json",
                        },
                        body: JSON.stringify({
                            "userId": userId,
                            "displayName": displayName,
                            "pictureUrl": pictureUrl,
                            "phone": phoneNum,
                            "points": "0",
                            "inviteCode": nanoid(10),
                            "tag": [],
                            "useInviteCode": "",
                        })
                    })
                        .then(inside_res => inside_res.json())
                        .then(inside_data => {
                            setFirstTimeVisit(false);
                            window.location.reload();
                        })

                } else {
                    toast.error('驗證失敗，請檢查您的驗證碼');
                }
            })
            .catch(error => {
                console.error('Error verifying OTP:', error);
                toast.error('驗證失敗，請稍後再試。');
            });
    }

    return (
        <>
            <Modal show={firstTimeVisit} centered>
                <Modal.Header>
                    <Modal.Title>
                        <span style={{ color: '#2C2351', fontFamily: 'Fraunces, Arial, sans-serif' }}>簡訊認證</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ color: '#2C2351' }}>
                    <Form.Group controlId="inputPhone">
                        <Form.Label>
                            <span style={{ fontFamily: 'Fraunces, Arial, sans-serif' }}>輸入您的手機號碼</span>
                        </Form.Label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Form.Control
                                type="number"
                                aria-describedby="phoneHelpBlock"
                                maxLength={10}
                                placeholder='0987654321'
                                onChange={changePhoneNum}
                                style={{ marginRight: '10px', flexBasis: '60%' }}
                            />
                            <Button
                                variant="primary"
                                onClick={handleSendOTP}
                                disabled={isOTPSent}
                                style={{ fontFamily: 'Fraunces, Arial, sans-serif', flexBasis: '40%', backgroundColor: 'rgba(44, 35, 81, 0.9)', borderColor: 'transparent' }}
                            >
                                {isOTPSent ? `重新發送 (${countdown})` : '手機認證'}
                            </Button>
                        </div>
                    </Form.Group>
                    <Form.Group controlId="inputOTP" style={{ marginTop: '20px' }}>
                        <Form.Label>
                            <span style={{ fontFamily: 'Fraunces, Arial, sans-serif' }}>輸入OTP</span>
                        </Form.Label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Form.Control
                                type="number"
                                aria-describedby="otpHelpBlock"
                                placeholder='輸入6碼OTP'
                                onChange={handleOTPChange}
                                style={{ marginRight: '10px', flexBasis: '60%' }}
                            />
                            <Button
                                variant="primary"
                                disabled={!isOTPSent}
                                onClick={verifyOTP}
                                style={{ fontFamily: 'Fraunces, Arial, sans-serif', flexBasis: '40%', backgroundColor: 'rgba(44, 35, 81, 0.9)', borderColor: 'transparent' }}
                            >
                                送出
                            </Button>
                        </div>
                    </Form.Group>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default OTPAuth;