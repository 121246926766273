// store.js
import { configureStore } from '@reduxjs/toolkit';

// 初始狀態
const initialState = {
    // 識別現在是哪個商家
    storeDB: '',

    storePartners: [],

    // User Info
    userId: '',
    displayName: '',
    pictureUrl: '',
    phone: '',
    points: '',
    inviteCode: '',
    useInviteCode: '',
    // End

    storeSetting: [],
    pointMethodList: [],
    appointmentList: [],
    appointmentHistory: [],
    selfAppointmentHistory: [],
    couponList: [],
    couponHistory: [],

};

// 定義 reducer 函數
const reducer = (state = initialState, action) => {

    switch (action.type) {
        case 'SET_STORE_DB':
            return { ...state, storeDB: action.payload };

        case 'SET_STORE_PARTNERS':
            return { ...state, storePartners: action.payload };

        case 'SET_STORE_SETTING':
            return { ...state, storeSetting: action.payload };

        case 'SET_USER_ID':
            return { ...state, userId: action.payload };

        case 'SET_DISPLAY_NAME':
            return { ...state, displayName: action.payload };

        case 'SET_PICTURE_URL':
            return { ...state, pictureUrl: action.payload };

        case 'SET_PHONE':
            return { ...state, phone: action.payload };

        case 'SET_POINTS':
            return { ...state, points: action.payload };

        case 'SET_INVITE_CODE':
            return { ...state, inviteCode: action.payload };

        case 'SET_USE_INVITE_CODE':
            return { ...state, useInviteCode: action.payload };

        // ----------------------------------------------------------------

        case 'SET_POINT_METHODS':
            return { ...state, pointMethodList: action.payload };

        case 'SET_APPOINTMENT_LISTS':
            return { ...state, appointmentList: action.payload };

        case 'SET_APPOINTMENT_HISTORY':
            return { ...state, appointmentHistory: action.payload };

        case 'SET_SELF_APPOINTMENT_HISTORY':
            return { ...state, selfAppointmentHistory: action.payload };

        case 'SET_COUPON_LIST':
            return { ...state, couponList: action.payload };

        case 'SET_COUPON_HISTORY':
            return { ...state, couponHistory: action.payload };

        default:
            return state;
    }
};

// 建立 Redux store
const store = configureStore({ reducer });

export default store;
