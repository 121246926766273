import { Container, Row, Col } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const UserName = ({ username }) => {
    const storeSetting = useSelector(state => state.storeSetting[0]);
    const mainColorStyle = storeSetting.mainColorStyle;

    return (
        <Container>
            <Row className="justify-content-center">
                <Col xs={12} className="text-center">
                    <p style={{ color: mainColorStyle, fontSize: "16px", fontFamily: 'Fraunces, Arial, sans-serif', margin: '1px 0' }}>{username}</p>
                </Col>
            </Row>
        </Container>
    );
}

export default UserName;