import { Container, Row, Col } from 'react-bootstrap';
import { IoMdCopy } from "react-icons/io";
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';

const InviteCode = ({ code }) => {
    const storeSetting = useSelector(state => state.storeSetting[0]);
    const mainColorStyle = storeSetting.mainColorStyle;

    const copyCode = () => {
        navigator.clipboard.writeText(code);
        toast.success(<span style={{ color: '#2C2351' }}>Copy!</span>, {
            duration: 3000,
        });
    }

    return (
        <Container>
            <Row className="justify-content-center">
                <Col xs={12} className="text-center">
                    <p style={{ color: mainColorStyle, fontSize: '14px', fontFamily: 'Fraunces, Arial, sans-serif', margin: '1px 0' }}>
                        邀請碼: {code} <IoMdCopy onClick={copyCode} size={20} style={{ marginLeft: '5px', cursor: 'pointer', verticalAlign: 'middle' }} />
                    </p>

                </Col>
            </Row>
        </Container>
    );
}

export default InviteCode;