import { Container, Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';

const CouponDetail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const exchangeId = searchParams.get('exchangeId');
    const title = searchParams.get('title');
    const pointCost = searchParams.get('pointCost');
    const currentTime = searchParams.get('currentTime');

    const displayName = useSelector(state => state.displayName);
    const storeSetting = useSelector(state => state.storeSetting[0]);
    const mainColorStyle = storeSetting.mainColorStyle;
    const subColorStyle = storeSetting.subColorStyle;


    return (
        <>
            <IoMdArrowRoundBack size={30} onClick={() => navigate(-1)} style={{ color: mainColorStyle, marginTop: '30px', marginLeft: '30px' }} />
            <Container className='d-flex flex-column justify-content-center align-items-center' style={{ width: '90%', marginTop: "20px", paddingBottom: "10vh" }}>
                <Card style={{ width: '90%' }}>
                    <Card.Body>
                        <Card.Title><span style={{ color: mainColorStyle, fontFamily: 'Fraunces, Arial, sans-serif' }}>編號: #{exchangeId}</span></Card.Title>
                        <Card.Subtitle className="mb-2 text-muted"><span style={{ color: subColorStyle, fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '14px' }}>{currentTime}</span></Card.Subtitle>
                        <Card.Text>
                            <br />
                            <span style={{ color: mainColorStyle, fontFamily: 'Fraunces, Arial, sans-serif' }}><li>兌換人: {displayName}</li></span>
                            <br />
                            <span style={{ color: mainColorStyle, fontFamily: 'Fraunces, Arial, sans-serif' }}><li>兌換項目: {title}</li></span>
                            <br />
                            <span style={{ color: mainColorStyle, fontFamily: 'Fraunces, Arial, sans-serif' }}><li>花費點數: {pointCost}點</li></span>
                        </Card.Text>
                        {/* <Card.Link href="#">Card Link</Card.Link>
                        <Card.Link href="#">Another Link</Card.Link> */}
                    </Card.Body>
                </Card>
            </Container>
        </>
    );
}

export default CouponDetail;