import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Navbar, Button } from 'react-bootstrap';
import { FaAngleRight } from "react-icons/fa6";
import { IoTimeOutline } from "react-icons/io5";

const SelectPartner = () => {
    const navigate = useNavigate();
    const storePartners = useSelector(state => state.storePartners);
    const storeSetting = useSelector(state => state.storeSetting[0]);
    const mainColorStyle = storeSetting.mainColorStyle;
    const subColorStyle = storeSetting.subColorStyle;

    const navigateToPartnerAppointmentList = (navbar) => {
        // navigate(`/appointment-date?appointmentTitle=${navbar.title}&appointmentTakeTime=${navbar.takeTime}&appointmentPrice=${navbar.price}&appointmentRemark=${navbar.remark}`);
        navigate(`/appointment-list?partnerId=${navbar.id}`)
    }

    return (
        <>
            <Container className='justify-content-center' style={{ marginTop: '20px', marginBottom: '20px' }}>
                {storePartners.map(navbar => (
                    <Navbar key={navbar.username} as={Button} onClick={() => { navigateToPartnerAppointmentList(navbar) }} style={{ marginTop: '10px', borderColor: '#2C2351', width: '100%', backgroundColor: 'transparent', borderRadius: '10px' }}>
                        <Container className="d-flex align-items-center">
                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <div style={{ flex: '0 0 70%', marginBottom: '5px', textAlign: 'left' }}>
                                    <Navbar.Brand style={{ color: '#fff', fontSize: '16px', fontFamily: 'Fraunces, Arial, sans-serif', whiteSpace: 'normal' }}>
                                        <span style={{ color: mainColorStyle }}>{navbar.username}</span>
                                    </Navbar.Brand>
                                </div>
                                <div style={{ flex: '0 0 30%', display: 'flex', textAlign: 'left' }}>
                                    <span style={{ fontSize: '12px', color: subColorStyle }}>{navbar.description}</span>
                                </div>
                            </div>
                            <Navbar.Collapse className="justify-content-end">
                                <FaAngleRight color={mainColorStyle} />
                            </Navbar.Collapse>
                        </Container>
                    </Navbar>
                ))}
            </Container>
        </>
    );
}

export default SelectPartner;