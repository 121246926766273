import { useNavigate } from 'react-router-dom';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap'
import { useSelector } from 'react-redux';

const CommonBtn = ({ points }) => {
    const navigate = useNavigate();

    const storeSetting = useSelector(state => state.storeSetting[0]);
    const mainColorStyle = storeSetting.mainColorStyle;
    const subColorStyle = storeSetting.subColorStyle;

    const navigateToEditProfile = () => {
        navigate("/edit-profile");
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
            <div style={{ width: '80vw' }}>
                <ToggleButtonGroup type="checkbox" style={{ height: '60px', borderRadius: '20px', borderColor: mainColorStyle, width: '100%', margin: '10px 0' }} >
                    <ToggleButton id="tbg-btn-1" style={{ backgroundColor: 'white', width: '50%', borderColor: mainColorStyle, borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px', pointerEvents: 'none' }}>
                        <div style={{ fontFamily: 'Fraunces, Arial, sans-serif', color: mainColorStyle, fontSize: '12px' }}>您的點數</div>
                        <div style={{ fontFamily: 'Fraunces, Arial, sans-serif', marginTop: '-8px', color: mainColorStyle, fontSize: '30px' }}>{points}<span style={{ color: mainColorStyle, fontSize: '12px' }}> 點</span></div>
                    </ToggleButton>
                    <ToggleButton as={'button'} onClick={navigateToEditProfile} id="tbg-btn-2" style={{ backgroundColor: mainColorStyle, width: '50%', borderColor: mainColorStyle, borderTopRightRadius: '20px', borderBottomRightRadius: '20px', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span style={{ color: '#fff', margin: '0', fontSize: '18px', fontFamily: 'Fraunces, Arial, sans-serif' }}>Edit<br />Profile</span>
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
        </div>
    );
}

export default CommonBtn;
