import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, ListGroup, Button, Modal, Form, FloatingLabel } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import crypto from 'crypto-js';
import Spinner from 'react-bootstrap/Spinner';
import toast from 'react-hot-toast';
import { IoMdArrowRoundBack, IoMdTime } from 'react-icons/io';

import flexModel from '../FlexModel.json';

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const AppointmentConfirm = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const storeDB = useSelector(state => state.storeDB);
    const storePartners = useSelector(state => state.storePartners);
    const storeSetting = useSelector(state => state.storeSetting[0]);
    const mainColorStyle = storeSetting.mainColorStyle;
    const subColorStyle = storeSetting.subColorStyle;

    const searchParams = new URLSearchParams(location.search);
    const partnerId = searchParams.get('partnerId');
    const date = searchParams.get('date');
    const time = searchParams.get('time');
    const title = searchParams.get('title');
    const price = searchParams.get('price');
    const takeTime = searchParams.get('takeTime');
    const remark = decodeURIComponent(searchParams.get('remark'));

    const partner = storePartners.find(p => p.id === partnerId);
    const partnerUsername = partner ? partner.username : 'Unknown Partner';

    const userId = useSelector(state => state.userId);
    const displayName = useSelector(state => state.displayName);

    const [forceOpenCloseDateTime, setForceOpenCloseDateTime] = useState([]);
    const [dbProcessing, setDbProcessing] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [comment, setComment] = useState('');

    const generateRandomCode = (title) => {
        const timestamp = Math.floor(Date.now() / 1000); // 获取当前时间戳（精确到秒）
        const inputString = `${timestamp}-${title}`; // 将当前时间戳与要加入的字符串进行拼接
        const hashValue = crypto.MD5(inputString).toString(); // 计算 MD5 哈希值

        // 截取哈希值的一部分作为随机码
        const randomCodePart = hashValue.substring(0, 10).toUpperCase(); // 截取前 10 位并转换为大写

        return randomCodePart; // 设置随机码
    };

    const confirmBtn = () => {
        setShowConfirm(true);
    }

    const confirmClose = () => {
        setShowConfirm(false);
    }

    const navigateToAppointmentDetail = (exchangeId, title, currentTime, bookDate, bookTime, price, takeTime, remark, commentToStore) => {
        navigate(`/appointment-detail?exchangeId=${exchangeId}&title=${title}&currentTime=${currentTime}&bookDate=${bookDate}&bookTime=${bookTime}&price=${price}&takeTime=${takeTime}&remark=${remark}&commentToStore=${commentToStore}`);
    }

    useEffect(() => {
        fetch(`${SERVER_BASE_URL}/api/get-all-force-open-close-date-time/${storeDB}`)
            .then(res => res.json())
            .then(data => {
                setForceOpenCloseDateTime(data);
            })
    }, []);

    const checkDateTimeWhetherForceOpenCloseOrNot = () => {
        /* 
        * 按下確定預約後，判斷該時間是否是「被強制打開的時段」
        * 若是: 則將它的 booked 欄位改成 true
        * 若不是: 不做任何事
        */
        const record = forceOpenCloseDateTime.find(record => record.bookDate === date && record.bookTime === time);
        if (record) {
            // 這裡的 record 沒意外的話
            // 1. action 都是 open
            // 2. booked 都是 false
            console.log(record);
            fetch(`${SERVER_BASE_URL}/api/remove-force-date-time/${storeDB}/${record.id}`, {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then(res => res.json())
                .then(data => {
                    console.log("[SUCESS] DELETE FORCE TIME");
                })
        } else {
            console.log("NOTHING CHANGE");
        }
    }

    const generateJson = (params) => {
        // 將模板中的佔位符替換為參數值
        const jsonString = JSON.stringify(flexModel)
            .replace('{{emoji}}', params.emoji)
            .replace('{{eventTitle}}', params.eventTitle)
            .replace('{{currentTime}}', params.currentTime)
            .replace('{{eventDescription}}', params.eventDescription)
            .replace('{{eventPrice}}', params.eventPrice)
        // .replace('{{eventTakeTime}}', params.eventTakeTime)
        // .replace('{{eventRemark}}', params.eventRemark)
        // .replace('{{eventCommentToStore}}', params.eventCommentToStore);

        return JSON.parse(jsonString);
    };


    const push_line_flex_message = (currentTime) => {
        const params = {
            emoji: '🎉',
            eventTitle: "成功完成預約",
            currentTime: `於 ${currentTime} 進行預約`,
            eventDescription: `您已成功預約 ${title}`,
            eventPrice: `期待在 ${date} ${time} 與您見面`,
            // eventTakeTime: `服務時間大約 ${takeTime}分鐘`,
            // eventRemark: `此服務注意事項: ${remark}`,
            // eventCommentToStore: `給店家的備註: ${comment}`
        };
        const pushFlex = generateJson(params);

        const encodedChannelAccessToken = encodeURIComponent(storeSetting.channelAccessToken);
        const encodedUserId = encodeURIComponent(userId);
        fetch(`${SERVER_BASE_URL}/api/push-line-flex-message/${encodedChannelAccessToken}/${encodedUserId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                messages: pushFlex,
            }),
        })
            .then(response => response.json())
            .then(data => {
                console.log('Message sent:', data);
            })
            .catch(error => {
                console.error('Error sending message:', error);
            });
    }


    const push_line_notify = () => {
        fetch(`${SERVER_BASE_URL}/api/push-line-notify/${storeSetting.lineNotifyToken}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                messages: `:新預約通知\n${partnerUsername}的預約\n客戶:${displayName}\n預約項目: ${title}\n預約時間: ${date} ${time}\n客戶留下的備註: ${comment}`
            })
        })
            .then(response => response.json())
            .then(data => {
                console.log('Message sent:', data);
            })
            .catch(error => {
                console.error('Error sending message:', error);
            });
    }


    const submitAppointment = () => {
        setDbProcessing(true);

        checkDateTimeWhetherForceOpenCloseOrNot();

        // 目前時間
        const now = new Date();
        const year = now.getFullYear();
        const month = (now.getMonth() + 1).toString().padStart(2, '0'); // 月份从 0 开始，需要 +1
        const day = now.getDate().toString().padStart(2, '0');
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
        const formattedTime = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
        // End

        // 兌換id
        const exchangeId = generateRandomCode(title);
        // End

        fetch(`${SERVER_BASE_URL}/api/add-appointment-history-return-all/${storeDB}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                "id": exchangeId,
                "userId": userId,
                "title": title,
                "currentTime": formattedTime,
                "bookDate": date,
                "bookTime": time,
                "price": price,
                "takeTime": takeTime,
                "remark": remark,
                "commentToStore": comment,
                "status": "pending",
                "partner": partnerId
                /* Status狀態 
                pending: 待辦
                done: 正常完成
                noshow: 放鳥
                cancel: 客人取消預約
                */
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                dispatch({ type: 'SET_APPOINTMENT_HISTORY', payload: data });

                push_line_flex_message(formattedTime);
                push_line_notify();

                navigateToAppointmentDetail(exchangeId, title, formattedTime, date, time, price, takeTime, remark, comment);
            })

        setDbProcessing(false);
        setShowConfirm(false);
        toast.success('預約成功', {
            duration: 5000,
        });
    }

    const handleCommentChange = (e) => {
        setComment(e.target.value);
    };



    return (
        <>
            <IoMdArrowRoundBack size={30} onClick={() => navigate(-1)} style={{ color: mainColorStyle, marginTop: '30px', marginLeft: '30px' }} />
            {
                dbProcessing ?
                    (
                        <div className="spinner-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    )
                    :
                    (
                        <Container className='d-flex flex-column justify-content-center align-items-center' style={{ width: '80%', marginTop: "20px" }}>
                            <p style={{ color: mainColorStyle, fontFamily: 'Fraunces, Arial, sans-serif', fontWeight: 'bold', fontSize: '18px' }}>請確認您的預約資訊</p>

                            <ListGroup as="ol" numbered style={{ width: '100%' }}>
                                <ListGroup.Item
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                    style={{ color: mainColorStyle }}
                                >
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">預約日</div>
                                        {date}
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                    style={{ color: mainColorStyle }}
                                >
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">時間</div>
                                        {time}
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                    style={{ color: mainColorStyle }}
                                >
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">預約項目</div>
                                        {title}
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                    style={{ color: mainColorStyle }}
                                >
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">為您服務的人員</div>
                                        {partnerUsername}
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                    style={{ color: mainColorStyle }}
                                >
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">服務金額</div>
                                        ${price} 起
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                    style={{ color: mainColorStyle }}
                                >
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">預計所花時間</div>
                                        <div>
                                            <IoMdTime /> {takeTime}分鐘
                                        </div>
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item
                                    as="li"
                                    className="d-flex justify-content-between align-items-start"
                                    style={{ color: mainColorStyle }}
                                >
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">此服務注意事項</div>
                                        <div style={{ whiteSpace: 'pre-wrap' }}>
                                            {remark === "" ? "-" : remark}
                                        </div>

                                    </div>
                                </ListGroup.Item>
                            </ListGroup>

                        </Container>
                    )
            }

            <Container className='d-flex flex-column justify-content-center align-items-center' style={{ marginTop: "20px", marginBottom: "100px" }}>
                <Button className="py-2 rounded-pill" variant="primary" onClick={confirmBtn} style={{ width: '60%', backgroundColor: mainColorStyle, borderColor: 'transparent' }}><span style={{ color: '#fff', fontWeight: 'bold', fontFamily: 'Fraunces, Arial, sans-serif' }}>確定預約</span></Button>
            </Container>

            <Modal show={showConfirm} onHide={confirmClose} centered>
                <Modal.Header closeButton>
                    <Modal.Title><span style={{ color: mainColorStyle, fontFamily: 'Fraunces, Arial, sans-serif' }}>留下備註</span></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ color: mainColorStyle }}>
                    {/* <p style={{ color: mainColorStyle }}>{remark}</p> */}
                    <div style={{ color: mainColorStyle, whiteSpace: 'pre-wrap' }}>{remark}</div>
                    <FloatingLabel controlId="floatingTextarea2" label="輸入必要資訊">
                        <Form.Control
                            as="textarea"
                            placeholder="Leave a comment here"
                            style={{ height: '100px' }}
                            value={comment}
                            onChange={handleCommentChange}
                        />
                    </FloatingLabel>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={confirmClose} style={{ fontFamily: 'Fraunces, Arial, sans-serif', borderColor: mainColorStyle, backgroundColor: 'transparent', color: mainColorStyle }}>
                        取消
                    </Button>
                    <Button variant="primary" onClick={submitAppointment} style={{ fontFamily: 'Fraunces, Arial, sans-serif', backgroundColor: mainColorStyle, color: '#fff', borderColor: 'transparent' }}>
                        送出
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    );
}

export default AppointmentConfirm;