import { useEffect, useState } from 'react';
import { Container, Card, Button, Modal } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import toast from 'react-hot-toast';

import flexModel from '../FlexModel.json';

const SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

const AppointmentDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const userId = useSelector(state => state.userId);
    const storeDB = useSelector(state => state.storeDB);
    const storeSetting = useSelector(state => state.storeSetting[0]);
    const mainColorStyle = storeSetting.mainColorStyle;
    const subColorStyle = storeSetting.subColorStyle;

    const searchParams = new URLSearchParams(location.search);
    const exchangeId = searchParams.get('exchangeId');
    const title = searchParams.get('title');
    const currentTime = searchParams.get('currentTime');
    const bookDate = searchParams.get('bookDate');
    const bookTime = searchParams.get('bookTime');
    const price = searchParams.get('price');
    const takeTime = searchParams.get('takeTime');
    const remark = searchParams.get('remark');
    const commentToStore = searchParams.get('commentToStore');
    const status = searchParams.get('status');

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [showConfirmMoadl, setShowConfirmMoadl] = useState(false);


    // 比較「今天日期」與「服務日期」，若服務日期為“今天以前(包含今天)”則不可取消預約
    useEffect(() => {
        const todayNow = new Date();
        const appointmentDate = new Date(bookDate + " " + bookTime);

        if (appointmentDate < todayNow) {
            setIsButtonDisabled(true);
        }
    }, []);
    // End

    const displayName = useSelector(state => state.displayName);

    const closeModal = () => {
        setShowConfirmMoadl(false);
    }

    const cancelAppointment = () => {
        setShowConfirmMoadl(true);
    }

    const generateJson = (params) => {
        // 將模板中的佔位符替換為參數值
        const jsonString = JSON.stringify(flexModel)
            .replace('{{emoji}}', params.emoji)
            .replace('{{eventTitle}}', params.eventTitle)
            .replace('{{currentTime}}', params.currentTime)
            .replace('{{eventDescription}}', params.eventDescription)
            .replace('{{eventPrice}}', params.eventPrice)
        // .replace('{{eventTakeTime}}', params.eventTakeTime)
        // .replace('{{eventRemark}}', params.eventRemark)
        // .replace('{{eventCommentToStore}}', params.eventCommentToStore);

        return JSON.parse(jsonString);
    };

    const confirmCancel = () => {
        fetch(`${SERVER_BASE_URL}/api/edit-appoinment-histort-status/${storeDB}/${exchangeId}`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                status: "cancel",
                /* Status狀態 
                pending: 代辦
                done: 正常完成
                noshow: 放鳥
                cancel: 客人取消預約
                */
            })
        })
            .then(res => res.json())
            .then(data => {
                console.log(data);
                dispatch({ type: 'SET_APPOINTMENT_HISTORY', payload: data });

                // 目前時間
                const now = new Date();
                const year = now.getFullYear();
                const month = (now.getMonth() + 1).toString().padStart(2, '0'); // 月份从 0 开始，需要 +1
                const day = now.getDate().toString().padStart(2, '0');
                const hours = now.getHours().toString().padStart(2, '0');
                const minutes = now.getMinutes().toString().padStart(2, '0');
                const seconds = now.getSeconds().toString().padStart(2, '0');
                const formattedTime = `${year}/${month}/${day} ${hours}:${minutes}:${seconds}`;
                // End

                const params = {
                    emoji: '😭',
                    eventTitle: "取消預約",
                    currentTime: `於 ${formattedTime} 取消`,
                    eventDescription: `已取消預約: ${title}`,
                    eventPrice: `取消時段 ${bookDate} ${bookTime}`,
                    // eventTakeTime: `服務時間大約 ${takeTime}分鐘`,
                    // eventRemark: `此服務注意事項: ${remark}`,
                    // eventCommentToStore: `給店家的備註: ${comment}`
                };
                const pushFlex = generateJson(params);

                const encodedChannelAccessToken = encodeURIComponent(storeSetting.channelAccessToken);
                const encodedUserId = encodeURIComponent(userId);
                fetch(`${SERVER_BASE_URL}/api/push-line-flex-message/${encodedChannelAccessToken}/${encodedUserId}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        messages: pushFlex,
                    }),
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log('Message sent:', data);
                    })
                    .catch(error => {
                        console.error('Error sending message:', error);
                    });

                fetch(`${SERVER_BASE_URL}/api/push-line-notify/${storeSetting.lineNotifyToken}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        messages: `:取消預約\n用戶:${displayName}\n取消項目: ${title}\n取消之時段: ${bookDate} ${bookTime}`
                    })
                })
                    .then(response => response.json())
                    .then(data => {
                        console.log('Message sent:', data);
                    })
                    .catch(error => {
                        console.error('Error sending message:', error);
                    });

                toast.success("已取消預約", {
                    duration: 3000
                });
                setShowConfirmMoadl(false);
                navigate(-1);
                // navigateToAppointmentDetail(exchangeId, title, formattedTime, date, time, price, takeTime, remark, comment);
            })
    }

    return (
        <>
            <IoMdArrowRoundBack size={30} onClick={() => navigate('/appointment')} style={{ color: mainColorStyle, marginTop: '30px', marginLeft: '30px' }} />
            <Container className='d-flex flex-column justify-content-center align-items-center' style={{ width: '90%', marginTop: "20px", paddingBottom: "10vh" }}>
                <Card style={{ width: '90%' }}>
                    <Card.Body>
                        <Card.Title><span style={{ color: mainColorStyle, fontFamily: 'Fraunces, Arial, sans-serif' }}>編號: #{exchangeId}</span></Card.Title>
                        <Card.Subtitle className="mb-2 text-muted"><span style={{ color: subColorStyle, fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '14px' }}>{currentTime}</span></Card.Subtitle>
                        <Card.Text style={{ color: mainColorStyle }}>
                            <br />
                            <span style={{ fontFamily: 'Fraunces, Arial, sans-serif' }}><li>預約人: {displayName}</li></span>
                            <br />
                            <span style={{ fontFamily: 'Fraunces, Arial, sans-serif' }}><li>預約項目: {title}</li></span>
                            <br />
                            <span style={{ fontFamily: 'Fraunces, Arial, sans-serif' }}><li>服務時間: {bookDate} {bookTime}</li></span>
                            <br />
                            <span style={{ fontFamily: 'Fraunces, Arial, sans-serif' }}><li>服務預計金額: ${price} 起</li></span>
                            <br />
                            <span style={{ fontFamily: 'Fraunces, Arial, sans-serif' }}><li>服務預計所花時間: {takeTime} mins</li></span>
                            <br />
                            <span style={{ fontFamily: 'Fraunces, Arial, sans-serif' }}><li>此服務備註: {remark === "" ? "-" : remark}</li></span>
                            <br />
                            <span style={{ fontFamily: 'Fraunces, Arial, sans-serif' }}><li>給店家之備註: {commentToStore}</li></span>
                            <br />
                            <span style={{ fontFamily: 'Fraunces, Arial, sans-serif' }}><li>訂單狀態: {status}</li></span>
                        </Card.Text>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button variant="primary" disabled={isButtonDisabled || status === "cancel"} onClick={cancelAppointment} style={{ width: '100%', backgroundColor: mainColorStyle, borderColor: 'transparent' }}><span style={{ color: '#fff', fontWeight: 'bold', fontFamily: 'Fraunces, Arial, sans-serif' }}>取消預約</span></Button>
                        </div>
                        {/* <Card.Link href="#">Another Link</Card.Link> } */}
                    </Card.Body>
                </Card>
            </Container>

            <Modal show={showConfirmMoadl} onHide={closeModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title><span style={{ color: mainColorStyle, fontFamily: 'Fraunces, Arial, sans-serif' }}>確定取消</span></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ color: mainColorStyle }}>
                    <span style={{ color: mainColorStyle, fontFamily: 'Fraunces, Arial, sans-serif' }}>確定要取消此筆預約嗎</span>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal} style={{ fontFamily: 'Fraunces, Arial, sans-serif', borderColor: mainColorStyle, backgroundColor: 'transparent', color: mainColorStyle }}>
                        返回
                    </Button>
                    <Button variant="primary" onClick={confirmCancel} style={{ fontFamily: 'Fraunces, Arial, sans-serif', backgroundColor: mainColorStyle, color: subColorStyle, borderColor: 'transparent' }}>
                        確定取消
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default AppointmentDetail;