import { Container, Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import Avatar from "../Components/UserInfo/Avatar";
import UserName from "../Components/UserInfo/UserName";
import InviteCode from "../Components/UserInfo/InviteCode";
import CommonBtn from "../Components/CommonBtn/CommonBtn";

import { useEffect, useState } from 'react';

const MemberMain = () => {
    const displayName = useSelector(state => state.displayName);
    const pictureUrl = useSelector(state => state.pictureUrl);
    const points = useSelector(state => state.points);
    const inviteCode = useSelector(state => state.inviteCode);
    const items = useSelector(state => state.pointMethodList);

    const storeSetting = useSelector(state => state.storeSetting[0]);
    const mainColorStyle = storeSetting.mainColorStyle;
    const subColorStyle = storeSetting.subColorStyle;

    return (
        <>
            {/* Common Components */}
            <Avatar imageUrl={pictureUrl} />
            <UserName username={displayName} />
            <InviteCode code={inviteCode} />
            <CommonBtn points={points} />
            {/* End */}

            <Container className='d-flex flex-column justify-content-center align-items-center' style={{ width: '80%', marginTop: "20px", paddingBottom: "10vh" }}>
                <p style={{ color: mainColorStyle, fontFamily: 'Fraunces, Arial, sans-serif', fontWeight: 'bold', fontSize: '18px' }}>最新資訊</p>

                <div style={{ width: '100%' }}>
                    <Accordion>
                        {items.map(item => (
                            <Accordion.Item key={item.title} eventKey={item.title.toString()}>
                                <Accordion.Header style={{ fontFamily: 'Fraunces, Arial, sans-serif' }}><span style={{ color: mainColorStyle }}>{item.title}</span></Accordion.Header>
                                <Accordion.Body style={{ fontSize: '14px', color: subColorStyle, fontFamily: 'Fraunces, Arial, sans-serif' }}>{item.content}</Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </div>
            </Container >

        </>
    );
}

export default MemberMain;