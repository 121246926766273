import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Container, Navbar, Button } from 'react-bootstrap';
import { FaAngleRight } from "react-icons/fa6";

const AppointmentHistory = () => {
    const navigate = useNavigate();
    const userId = useSelector(state => state.userId);
    const historyData = useSelector(state => state.appointmentHistory);
    const storeSetting = useSelector(state => state.storeSetting[0]);
    const mainColorStyle = storeSetting.mainColorStyle;
    const subColorStyle = storeSetting.subColorStyle;


    // 只挑出自己的 appointmentHistory
    const filteredHistory = historyData.filter(appointment => appointment.userId === userId);
    const len_historyData = filteredHistory.length;

    const navigateToAppointmentDetail = (exchangeId, title, currentTime, bookDate, bookTime, price, takeTime, remark, commentToStore, status) => {
        navigate(`/appointment-detail?exchangeId=${exchangeId}&title=${title}&currentTime=${currentTime}&bookDate=${bookDate}&bookTime=${bookTime}&price=${price}&takeTime=${takeTime}&remark=${remark}&commentToStore=${commentToStore}&status=${status}`);
    }

    return (
        len_historyData === 0 ?
            (
                <Container className='d-flex flex-column justify-content-center align-items-center' style={{ width: '80%', marginTop: "20px", paddingBottom: "10vh" }}>
                    <p style={{ color: mainColorStyle, fontFamily: 'Fraunces, Arial, sans-serif', fontSize: '18px' }}>目前尚未有預約紀錄!</p>
                </Container>
            )
            :
            (
                < Container className='justify-content-center' style={{ marginTop: '20px', marginBottom: '20px' }}>
                    {
                        filteredHistory.map(navbar => (
                            <Navbar key={navbar.id} as={Button} onClick={() => { navigateToAppointmentDetail(navbar.id, navbar.title, navbar.currentTime, navbar.bookDate, navbar.bookTime, navbar.price, navbar.takeTime, navbar.remark, navbar.commentToStore, navbar.status); }} style={{ borderBottomColor: 'rgba(44, 35, 81, 0.2)', borderBottomLeftRadius: '0%', borderBottomRightRadius: '0%', borderTopColor: 'transparent', borderLeftColor: 'transparent', borderRightColor: 'transparent', marginTop: '10px', width: '100%', backgroundColor: 'transparent' }}>
                                <Container className="d-flex align-items-center">
                                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                        <div style={{ flex: '0 0 70%', marginBottom: '5px', textAlign: 'left' }}>
                                            <Navbar.Brand style={{ color: '#fff', fontSize: '16px', fontFamily: 'Fraunces, Arial, sans-serif', whiteSpace: 'normal' }}>
                                                <span style={{ fontSize: '14px', color: mainColorStyle }}>預約項目: {navbar.title}</span>
                                            </Navbar.Brand>
                                        </div>
                                        <div style={{ flex: '0 0 30%', display: 'flex', alignItems: 'center' }}>
                                            <span style={{ fontSize: '10px', color: subColorStyle }}>在{navbar.currentTime}下單: {navbar.bookTime}</span>
                                        </div>
                                    </div>
                                    <Navbar.Collapse className="justify-content-end">
                                        <FaAngleRight color={mainColorStyle} />
                                    </Navbar.Collapse>
                                </Container>
                            </Navbar>
                        ))
                    }
                </Container >
            )
    );
}

export default AppointmentHistory;