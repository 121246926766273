import { Container, Row, Col, Image } from 'react-bootstrap';
import './Avatar.css';

const Avatar = ({ imageUrl }) => {
    return (
        <Container className="avatar-container">
            <Row className="justify-content-center">
                <Col xs={12} className="text-center">
                    <div className="avatar-wrapper">
                        <Image src={imageUrl} roundedCircle className="avatar-image" />
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Avatar;