import { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import { Link } from "react-router-dom";
import { GoPeople } from "react-icons/go";
import { VscCalendar } from "react-icons/vsc";
import { RiCoupon3Line } from "react-icons/ri";
import { useSelector } from 'react-redux';

import styles from './Footer.module.css';

const Footer = ({ mainColorStyle, subColorStyle }) => {
    const [activeNavItem, setActiveNavItem] = useState('member-main');

    const handleNavSelect = (selectedKey) => {
        setActiveNavItem(selectedKey);
    };

    return (
        <div className={styles.footerContainer}>
            <Nav justify variant="pills" activeKey={activeNavItem} onSelect={handleNavSelect}>
                <Nav.Item>
                    <Nav.Link eventKey="coupon" as={Link} to="/coupon" className={`${styles.navLink} ${activeNavItem === 'coupon' ? styles.activeNavLink : ''}`}>
                        <RiCoupon3Line size={30} color={mainColorStyle} />
                        <br />
                        <span style={{ color: mainColorStyle, fontSize: '16px', fontFamily: 'Fraunces, Arial, sans-serif' }}>優惠兌換</span>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="appointment" as={Link} to="/appointment" className={`${styles.navLink} ${activeNavItem === 'appointment' ? styles.activeNavLink : ''}`}>
                        <VscCalendar size={30} color={mainColorStyle} />
                        <br />
                        <span style={{ color: mainColorStyle, fontSize: '16px', fontFamily: 'Fraunces, Arial, sans-serif' }}>我要預約</span>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="member-main" as={Link} to="/" className={`${styles.navLink} ${activeNavItem === 'member-main' ? styles.activeNavLink : ''}`}>
                        <GoPeople size={30} color={mainColorStyle} />
                        <br />
                        <span style={{ color: mainColorStyle, fontSize: '16px', fontFamily: 'Fraunces, Arial, sans-serif' }}>會員主頁</span>
                    </Nav.Link>
                </Nav.Item>
            </Nav>
        </div>
    );
}

export default Footer;
