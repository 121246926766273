import { Container, Navbar } from 'react-bootstrap';

const Header = ({ storeName, mainColorStyle, subColorStyle }) => {

    return (
        <Navbar expand="lg" className="bg-white" sticky="top" style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.2)', height: '100px' }}>
            <Container className="d-flex justify-content-center align-items-center h-100">
                <Navbar.Brand className="text-center d-flex flex-column">
                    <p style={{ color: mainColorStyle, fontFamily: 'Fraunces, Arial, sans-serif', fontWeight: 'bold', fontSize: '20px', margin: '0' }}>{storeName}</p>
                    <span style={{ fontFamily: 'Fraunces, Arial, sans-serif', color: subColorStyle, fontSize: '10px', marginTop: '2px' }}>Powered By WEIZ STUDIO</span>
                </Navbar.Brand>
            </Container>
        </Navbar>
    );
}

export default Header;
