import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";

const AddInviteCode = () => {
    const navigate = useNavigate();

    return (
        <>
            <IoMdArrowRoundBack size={30} onClick={() => navigate(-1)} style={{ color: '#0D7A7B', marginTop: '30px', marginLeft: '30px' }} />
        </>
    );
}

export default AddInviteCode;